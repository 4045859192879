import { types, Instance, SnapshotIn } from "mobx-state-tree";
import TicketModel from "./TicketModel";
import { UserService } from "services";
import { flow } from "mobx-state-tree";
import { applySnapshot } from "mobx-state-tree";
import moment from "moment";
import alertUtil from "util/alertUtil";
import { ROLES } from "constants/ROLES";
import { INDUSTRY, INDUSTRY_NL } from "constants/INDUSTRY";

const UserModel = types
  .model("UserModel", {
    id: types.identifierNumber,
    email: types.string,
    firstName: types.string,
    lastName: types.string,
    fullName: types.string,
    dateOfBirth: types.string,
    phoneNumber: types.string,
    company: types.maybe(types.string),
    image: types.maybe(types.string),
    tickets: types.maybeNull(types.array(TicketModel)),
    currentIndustry: types.maybe(types.string),
    shareInformation: true,
    shareName: types.boolean,
    shareCompany: types.boolean,
    shareEmail: types.boolean,
    sharePhoneNumber: types.boolean,
    role: types.string,
    activatedAt: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.string),
    logs: types.array(
      types.model("UserLogModel", {
        message: types.string,
        createdAt: types.string,
      })
    ),
  })
  .actions((self) => ({
    // @ts-ignore
    update: flow(function* (userData: Partial<SnapshotIn<typeof UserModel>>) {
      const user = yield UserService.update(self.id, userData);
      applySnapshot(self, user);
    }),
    activate: flow(function* () {
      const user = yield UserService.activate(self.id);
      applySnapshot(self, user);
    }),
    archive: flow(function* () {
      const result = yield UserService.delete(self.id);
      if (result) {
        alertUtil.success("De gebruiker is gearchiveerd");
      }

      return result;
    }),
  }))
  .views((self) => ({
    get Role() {
      switch (self.role) {
        case ROLES.ADMIN:
          return "Beheerder";
        case ROLES.WIJTECHNIEK:
          return "WijTechniek";
        default:
          return "Gebruiker";
      }
    },
    get DateOfBirth() {
      return moment(self.dateOfBirth).format("DD-MM-yyyy");
    },
    get CurrentIndustry() {
      switch (self.currentIndustry) {
        case INDUSTRY.EDUCATION:
          return INDUSTRY_NL.EDUCATION;
        case INDUSTRY.CONSTRUCTION:
          return INDUSTRY_NL.CONSTRUCTION;
        case INDUSTRY.INSTALLATION_SECTOR:
          return INDUSTRY_NL.INSTALLATION_SECTOR;
        case INDUSTRY.FINANCIAL_SERVICES:
          return INDUSTRY_NL.FINANCIAL_SERVICES;
        case INDUSTRY.OTHER:
          return INDUSTRY_NL.OTHER;
        default:
          return INDUSTRY_NL.NOT_CHOSEN;
      }
    },
  }));

export interface UserInstance extends Instance<typeof UserModel> {}
export interface UserSnapshot extends SnapshotIn<typeof UserModel> {}

// @ts-ignore
export default UserModel;
