import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { AuthService } from "services";
import { RootStore } from "stores";
import alertUtil from "util/alertUtil";

const ResetPasswordForm = ({ match }) => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const submittable = password !== confirmPassword || password === "";

  const handleSubmit = async (event) => {
    if (event.currentTarget.checkValidity()) {
      event.preventDefault();

      if (
        password.length > 0 &&
        confirmPassword.length > 0 &&
        password === confirmPassword
      ) {
        const success = await AuthService.changePassword(
          password,
          match.params.token
        );

        if (success) {
          alertUtil.success("Uw wachtwoord is gewijzigd");
          setSuccess(success);

          if (!RootStore.isAuthenticated && !match.params.token) {
            return history.push("/login");
          }
        }
      }
    }
  };

  if (match.params.token && success)
    return (
      <>
        Je wachtwoord is gewijzigd. Je kan dit tabblad nu sluiten en opnieuw
        inloggen in de app!
      </>
    );

  return (
    <>
      {match.params.token && (
        <div className="brand-logo">
          <img src={require("../../assets/images/logo-hvs.png")} alt="logo" />
        </div>
      )}

      <h6 className="font-weight-light">Wijzig je wachtwoord</h6>
      <Form className="pt-3" onSubmit={handleSubmit}>
        <Form.Group className="d-flex">
          <Form.Control
            type="password"
            placeholder="Wachtwoord"
            size="lg"
            className="h-auto"
            required
            onChange={(event) => setPassword(event.target.value)}
          />
        </Form.Group>
        <Form.Group className="d-flex mb-3">
          <Form.Control
            type="password"
            placeholder="Bevestig je wachtwoord"
            size="lg"
            className="h-auto"
            required
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </Form.Group>

        <p className="text-danger mt-0">
          {password.length > 0 &&
          confirmPassword.length > 0 &&
          password !== confirmPassword
            ? "De ingevulde wachtwoorden komen niet overeen"
            : ""}
        </p>

        <div className="mt-3">
          <button
            disabled={submittable}
            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
          >
            VERSTUREN
          </button>
        </div>
      </Form>
    </>
  );
};

export default observer(ResetPasswordForm);
