import { observer } from "mobx-react-lite";
import React from "react";
import { Form } from "react-bootstrap";
import { AuthService } from "services";
import ApiService from "services/ApiService";
import { useStores } from "stores";
import alertUtil from "util/alertUtil";
import errorUtil from "util/errorUtil";

const TwoFactorRemoveForm = () => {
  const RootStore = useStores();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await AuthService.twoFactorAuthRemove(
      event.target[0].value,
      event.target[1].value
    );

    if (response.status === 204) {
      alertUtil.success("Je 2fa is verwijderd. Je kunt het opnieuw instellen.");

      RootStore.setHas2faEnabled(false);
      RootStore.setComplete2fa(false);
      ApiService.clearLocalStorageTwoFA();
    } else {
      errorUtil.handleError();
    }
  };

  return (
    <>
      <h6 className="font-weight-light">Verwijder uw 2FA</h6>

      <p>
        Als u uw 2FA-apparaat bent kwijtgeraakt, kunt u uw 2FA verwijderen om
        het te resetten. Voer uw herstelcode en uw e-mailadres in ter
        bevestiging. U wordt uitgelogd.
      </p>

      <Form className="pt-3" onSubmit={handleSubmit}>
        <Form.Group className="d-flex">
          <Form.Control
            type="email"
            placeholder="info@tmrk.nl"
            size="lg"
            className="h-auto"
            required
          />
        </Form.Group>
        <Form.Group className="d-flex mb-3">
          <Form.Control
            type="recoveryCode"
            placeholder="123abc12ab"
            size="lg"
            className="h-auto"
            required
          />
        </Form.Group>

        <div className="mt-3">
          <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
            2FA VERWIJDEREN
          </button>
        </div>
      </Form>
    </>
  );
};

export default observer(TwoFactorRemoveForm);
