import {
  types,
  Instance,
  SnapshotIn,
  applySnapshot,
  flow,
} from "mobx-state-tree";
import { EventService } from "services";
import alertUtil from "util/alertUtil";

const EventModel = types
  .model("EventModel", {
    id: types.identifierNumber,
    name: types.string,
    link: types.maybeNull(types.string),
    maximumRoomCapacity: types.number,
    testFragment: types.maybe(types.string),
    introFragment: types.maybe(types.string),
    practicalFragment: types.maybe(types.string),
    type: types.enumeration(["Theater", "External"]),
  })
  .actions((self) => ({
    update: flow(function* (
      id: number,
      // @ts-ignore
      eventData: Partial<SnapshotIn<typeof EventModel>>,
      files: any
    ) {
      const event = yield EventService.update(id, eventData, files);

      if (event) {
        applySnapshot(self, event);
        alertUtil.success("Het evenement is bijgewerkt");
      }
    }),
  }))
  .views((self) => ({
    get isTheater() {
      return self.type === "Theater";
    },
  }));

export interface EventInstance extends Instance<typeof EventModel> {}
export interface EventSnapshot extends SnapshotIn<typeof EventModel> {}

export default EventModel;
