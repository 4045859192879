import CrudService from "./CrudService";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { PerformanceSnapshot } from "stores/PerformanceStore/PerformanceModel";
import ApiService from "./ApiService";
import { LocationSnapshot } from "stores/LocationStore/LocationModel";
import moment from "moment";

// @ts-ignore
interface PerformanceRequestModel extends PerformanceSnapshot {
  location?: LocationSnapshot;
}

class PerformanceService extends CrudService<PerformanceRequestModel> {
  listWithIncludes = async (
    filters: RequestFiltersSnapshot,
    includeArchive: boolean = false
  ): Promise<PaginatedResponse<PerformanceSnapshot>> => {
    const response = await this.list(filters, {
      includeArchive: includeArchive ? 1 : 0,
    });

    response.results = response.results.map((performance) => {
      return this.transform(performance);
    });

    return response;
  };

  singleWithIncludes = async (id: number): Promise<PerformanceSnapshot> => {
    const performance = await this.single(id);

    return this.transform(performance);
  };

  updateWithIncludes = async (
    id: number,
    performanceData: Partial<PerformanceSnapshot>
  ): Promise<PerformanceSnapshot> => {
    const performance = await this.update(id, performanceData);

    return this.transform(performance);
  };

  createWithIncludes = async (
    performanceData: Partial<PerformanceSnapshot>
  ): Promise<PerformanceSnapshot> => {
    const performance = await this.create(performanceData);

    return this.transform(performance);
  };

  transform = (performance: PerformanceRequestModel): PerformanceSnapshot => {
    if (performance) {
      performance.locationName = performance.location.name;
    }

    return performance;
  };

  export = async (id: number) => {
    const response = await ApiService.post(`${this.basename}/export/${id}`);

    return response.data;
  };

  exportScannedAttendees = async (id: number) => {
    const response = await ApiService.post(
      `${this.basename}/export/${id}/scanned`
    );

    return response.data;
  };

  exportHelpQuestions = async (id: number) => {
    const response = await ApiService.post(
      `${this.basename}/export/${id}/helpquestions`
    );

    return response.data;
  };

  getDates = async (includeArchive: boolean): Promise<Date[]> => {
    const response = await ApiService.get(`${this.basename}/dates`, {
      includeArchive: includeArchive ? 1 : 0,
    });

    return response.data.map((date: string) => moment(date).toDate());
  };
}

export default new PerformanceService("/performances");
