import axios, { AxiosResponse, AxiosInstance, AxiosError } from "axios";
import moment from "moment";
import { RootStore } from "stores";
import errorUtil from "util/errorUtil";

class ApiService {
  instance: AxiosInstance;
  currentToken: TokenInterface | null;
  has2faEnabled: boolean;
  complete2fa: boolean;

  constructor() {
    this.instance = axios.create({
      baseURL: this.getUrl(),
    });

    this.currentToken = this.loadAccessToken();
    RootStore.setHas2faEnabled(this.loadHas2faEnabled());
    RootStore.setComplete2fa(this.loadComplete2fa());

    if (this.currentToken) {
      if (this.currentToken.tokenExpireDate <= moment()) {
        this.refreshToken();
      } else {
        RootStore.setIsAuthenticated(true);
      }
    } else {
      RootStore.setIsAuthenticated(false);
      RootStore.setHasLoaded(false);
    }

    this.setupInterceptors();
  }

  public get = async (url, query?): Promise<AxiosResponse> => {
    return this.request("get", url, null, query);
  };

  public post = async (url, body?): Promise<AxiosResponse> => {
    return this.request("post", url, body);
  };

  public put = async (url, body?): Promise<AxiosResponse> => {
    return this.request("put", url, body, null);
  };

  public delete = async (url): Promise<AxiosResponse> => {
    return await this.request("delete", url);
  };

  private request = async (
    method: any,
    url: string,
    body?: any,
    query?: any
  ): Promise<AxiosResponse> => {
    if (this.currentToken && this.currentToken.tokenExpireDate <= moment()) {
      await this.refreshToken();
    }

    return this.instance.request({
      method: method,
      url: url,
      data: body,
      params: query,
    });
  };

  private loadAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      return null;
    }

    const tokenExpireDate = moment(localStorage.getItem("token_expire_date"));
    this.setAccessTokenHeader(accessToken);

    return { accessToken, tokenExpireDate };
  };

  private loadHas2faEnabled = () => {
    const has2faEnabled = localStorage.getItem("2fa_enabled");

    this.has2faEnabled = !!has2faEnabled;
    return !!has2faEnabled;
  };

  private loadComplete2fa = () => {
    const complete2fa = localStorage.getItem("2fa_complete");

    this.complete2fa = !!complete2fa;
    return !!complete2fa;
  };

  public setAccessToken = (token: string, token_expires_in: number) => {
    const tokenExpireDate = moment();
    tokenExpireDate.add(token_expires_in, "m");
    localStorage.setItem("token_expire_date", tokenExpireDate.toISOString());
    localStorage.setItem("access_token", token);

    this.currentToken = { accessToken: token, tokenExpireDate };
    this.setAccessTokenHeader(token);
    RootStore.setIsAuthenticated(true);
  };

  public setAccessTokenHeader = (token: string) => {
    this.instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  public setEventId = (id: number) => {
    this.instance.defaults.headers.common["eventId"] = id;
  };

  private refreshToken = async (): Promise<boolean> => {
    try {
      var response = await this.instance.post("/auth/refresh");
      var authResponse = response.data;
      if (authResponse) {
        this.setAccessToken(authResponse.access_token, authResponse.expires_in);
        return true;
      }

      RootStore.reset();
      this.clearLocalStorageToken();
      this.clearLocalStorageTwoFA();
      return false;
    } catch (e) {
      RootStore.reset();
      this.clearLocalStorageToken();
      this.clearLocalStorageTwoFA();
      return false;
    }
  };

  public setHas2faEnabled = (has2faEnabled: boolean) => {
    this.has2faEnabled = has2faEnabled;
    localStorage.setItem("2fa_enabled", has2faEnabled ? "true" : "");
  };

  public setComplete2fa = (complete2fa: boolean) => {
    this.complete2fa = complete2fa;
    localStorage.setItem("2fa_complete", complete2fa ? "true" : "");
  };

  public clearLocalStorageToken() {
    localStorage.removeItem("token_expire_date");
    localStorage.removeItem("access_token");
  }

  public clearLocalStorageTwoFA() {
    localStorage.removeItem("2fa_enabled");
    localStorage.removeItem("2fa_complete");
  }

  private setupInterceptors = () => {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (errorResponse: AxiosError) => {
        console.log("I caught it");

        if (
          errorResponse.response &&
          errorResponse.response.data &&
          errorResponse.response.data.code &&
          errorResponse.response.data.code
        ) {
          errorUtil.handleError(errorResponse.response.data.code);
        } else {
          // errorUtil.handleError();
        }

        return errorResponse;
      }
    );
  };

  public getUrl(): string {
    const branch = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF;

    if (branch && branch === "develop") {
      return process.env.REACT_APP_API_URL_DEV;
    }

    return process.env.REACT_APP_API_URL;
  }
}

interface TokenInterface {
  accessToken: string;
  tokenExpireDate: moment.Moment;
}

export default new ApiService();
