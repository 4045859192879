import React from "react";
import { observer } from "mobx-react-lite";
import { ResetPasswordForm, TwoFactorRemoveForm } from "components/forms";

const ResetPassword = ({ match }) => {
  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-6 col-md-6">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <ResetPasswordForm match={match} />
            </div>
          </div>
        </div>

        {!match.params.token && (
          <div className="col-xl-6 col-md-6">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <TwoFactorRemoveForm />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ResetPassword);
