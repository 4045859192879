import {
  types,
  SnapshotIn,
  Instance,
  flow,
  applySnapshot,
} from "mobx-state-tree";
import { PostService } from "services";
import alertUtil from "util/alertUtil";

const PostModel = types
  .model("PostModel", {
    id: types.identifierNumber,
    type: types.enumeration(["NEWS", "VIDEO"]),
    phase: types.enumeration(["PRE_PERFORMANCE", "POST_PERFORMANCE", "ALWAYS"]),
    featured: types.boolean,
    active: types.boolean,
    thumbnail: types.maybe(types.string),
    headerImage: types.maybe(types.string),
    title: types.maybe(types.string),
    subtitle: types.maybe(types.string),
    introduction: types.maybe(types.string),
    video: types.maybe(types.string),
    bodyImage: types.maybe(types.string),
    imageCaption: types.maybe(types.string),
    firstBody: types.maybe(types.string),
    secondBody: types.maybe(types.string),
    secondTitle: types.maybe(types.string),
    thirdBody: types.maybe(types.string),
    videoCaption: types.maybe(types.string),
    roomId: types.maybe(types.number),
    roomName: types.maybe(types.string),
    employeeId: types.maybe(types.number),
    employeeName: types.maybe(types.string),
    statementId: types.maybe(types.number),
  })
  .actions((self) => ({
    update: flow(function* (
      // @ts-ignore
      postData: Partial<SnapshotIn<typeof PostModel>>,
      files: any
    ) {
      const post = yield PostService.updateWithIncludes(
        self.id,
        postData,
        files
      );

      if (post) {
        post.employeeName = self.employeeName;
        post.roomName = self.roomName;

        applySnapshot(self, post);
        alertUtil.success("Het nieuwsbericht is bijgewerkt");
      }
    }),
    getValues: () => {
      var values = [];
      for (var key of Object.keys(self)) {
        if (self[key] !== undefined) {
          values[key] = self[key];
        }
      }
      return values;
    },
  }))
  .actions((self) => ({
    setFeatured: flow(function* (value: boolean) {
      self.featured = value;
      yield self.update(self.getValues(), []);
    }),
    setActive: flow(function* (value: boolean) {
      self.active = value;
      yield self.update(self.getValues(), []);
    }),
  }))
  .views((self) => ({
    get link() {
      if (self.roomName) {
        return `Kamer '${self.roomName}'`;
      }

      if (self.employeeName) {
        return `Medewerker '${self.employeeName}'`;
      }

      return "";
    },
  }));

export interface PostInstance extends Instance<typeof PostModel> {}
export interface PostSnapshot extends SnapshotIn<typeof PostModel> {}

export default PostModel;
