import ApiService from "./ApiService";
import { RootStore } from "stores";

class AuthService {
  basename: string = "auth";

  public login = async (email: string, password: string): Promise<boolean> => {
    const response = await ApiService.post(`${this.basename}/login`, {
      email,
      password,
    });

    if (response.data) {
      const authResponse = response.data;

      ApiService.setHas2faEnabled(authResponse["2fa_enabled"]);
      RootStore.setHas2faEnabled(authResponse["2fa_enabled"]);

      ApiService.setAccessToken(
        authResponse.access_token,
        authResponse.expires_in
      );

      return true;
    }

    return false;
  };

  public twoFactorAuth = async (code: string) => {
    const response = await ApiService.post(`${this.basename}/2fa/validate`, {
      code,
    });

    return response;
  };

  public twoFactorAuthGetSetup = async () => {
    const response = await ApiService.get(`${this.basename}/2fa/setup`);

    return response;
  };

  public twoFactorAuthCompleteSetup = async (
    code: string,
    authenticatorKey: string
  ) => {
    const response = await ApiService.post(`${this.basename}/2fa/setup`, {
      code,
      authenticatorKey,
    });

    return response;
  };

  public twoFactorAuthRemove = async (email, recoveryCode) => {
    const response = await ApiService.put(`${this.basename}/2fa/remove`, {
      email,
      recoveryCode,
    });

    return response;
  };

  public logout = async () => {
    RootStore.reset();
    ApiService.clearLocalStorageToken();
    ApiService.clearLocalStorageTwoFA();
  };

  public forgot = async (email: string) => {
    const response = await ApiService.post(`${this.basename}/forgot`, {
      email,
    });

    return response.status === 200;
  };

  public changePassword = async (password: string, token?: string) => {
    const response = await ApiService.post(`${this.basename}/reset`, {
      password,
      resetToken: token,
    });

    return response.status === 200;
  };

  public activateEmail = async (token: string, isUpdate: boolean = false) => {
    const endpoint = isUpdate
      ? "users/validateEmail"
      : `${this.basename}/activate`;
    const response = await ApiService.get(`/${endpoint}/${token}`);
    return response.status === 200;
  };
}

export default new AuthService();
