import {
  types,
  Instance,
  SnapshotIn,
  flow,
  applySnapshot,
} from "mobx-state-tree";
import EventModel from "stores/EventStore/EventModel";
import { LocationService } from "services";
import LocationRoomModel from "./LocationRoomModel";
import alertUtil from "util/alertUtil";

const LocationModel = types
  .model({
    id: types.identifierNumber,
    name: types.string,
    street: types.string,
    houseNumber: types.maybe(types.string),
    zipCode: types.string,
    city: types.string,
    country: types.string,
    event: types.reference(EventModel),
    eventId: types.number,
    rooms: types.maybeNull(types.array(LocationRoomModel)),
  })
  .actions((self) => ({
    update: flow(function* (
      // @ts-ignore
      locationData: Partial<SnapshotIn<typeof LocationModel>>
    ) {
      const location = yield LocationService.updateWithIncludes(
        self.id,
        locationData
      );

      if (location) {
        applySnapshot(self, location);
        alertUtil.success("De locatie is bijgewerkt");
      }
    }),
  }));

export interface LocationInstance extends Instance<typeof LocationModel> {}
export interface LocationSnapshot extends SnapshotIn<typeof LocationModel> {}

export default LocationModel;
