import { Loader } from "components";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthService } from "services";
import { useStores } from "stores";
import { QRCodeSVG } from "qrcode.react";

interface TwoFactorSetupData {
  formattedKey: string;
  authenticatorKey: string;
}

const TWO_FACTOR_AUTHENTICATION_SETUP_COMPLETE_BUTTON_DISABLE_TIME = 5000;

const TwoFactorSetup = () => {
  const RootStore = useStores();

  const [loading, setLoading] = useState(false);
  const [twoFASetupData, setTwoFASetupData] = useState<TwoFactorSetupData>();
  const [recoveryCode, setRecoveryCode] = useState<string>();
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await AuthService.twoFactorAuthGetSetup();

      setLoading(false);
      setTwoFASetupData(data);
    };

    fetchData().catch(console.error);
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    const { data: recoveryData } = await AuthService.twoFactorAuthCompleteSetup(
      event.target[0].value,
      twoFASetupData.authenticatorKey
    );

    setRecoveryCode(recoveryData.recoveryCode);

    setTimeout(() => {
      setIsContinueDisabled(false);
    }, TWO_FACTOR_AUTHENTICATION_SETUP_COMPLETE_BUTTON_DISABLE_TIME);

    setLoading(false);
  };

  const handleBackButtonClick = () => {
    RootStore.reset();
  };

  const handleContinueButtonClick = () => {
    RootStore.setComplete2fa(true);
  };

  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-md-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img
                  src={require("../../assets/images/logo-full.png")}
                  alt="logo"
                />
              </div>

              <h4>TheaterMakers Dashboard</h4>

              {recoveryCode ? (
                <>
                  <div>
                    <h6>
                      De 2FA-installatie is succesvol voltooid! Dit is uw
                      herstelcode. Schrijf deze ergens op, u hebt deze nodig als
                      u uw 2FA wilt verwijderen
                    </h6>

                    <p style={{ fontSize: "40px", textAlign: "center" }}>
                      {recoveryCode}
                    </p>
                  </div>

                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <button
                      disabled={isContinueDisabled}
                      type="button"
                      onClick={handleContinueButtonClick}
                      className="btn btn-block btn-primary btn-lg font-weight-medium font-xl auth-form-btn position-relative"
                    >
                      Naar dashboard
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h5>Stel 2FA in om door te gaan</h5>

                  <h6 className="font-weight-light">
                    Gebruik een authenticator-app (bijv. Google Authenticator)
                    om de onderstaande QR-code te scannen
                  </h6>

                  {twoFASetupData && (
                    <div className="d-flex justify-content-center py-4">
                      <QRCodeSVG
                        value={twoFASetupData.formattedKey}
                        title="Theatermakers dashboard"
                      />
                    </div>
                  )}

                  <h6 className="font-weight-light">
                    Nadat je de QR-code hebt gescand, voer je de eenmalige code
                    uit de app hieronder in
                  </h6>

                  <Form className="pt-3" onSubmit={handleSubmit}>
                    <Form.Group className="d-flex search-field">
                      <Form.Control
                        type="twoFactor"
                        placeholder="012345"
                        size="lg"
                        className="h-auto"
                        required
                        disabled={!!recoveryCode}
                      />
                    </Form.Group>
                    <>
                      <div className="mt-3">
                        <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn position-relative">
                          INSTELLING 2FA
                          <Loader
                            display={loading}
                            position={"right"}
                            style={{ top: "6px", right: "15px" }}
                            size={40}
                          ></Loader>
                        </button>
                      </div>

                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <Link
                          to="login"
                          onClick={handleBackButtonClick}
                          className="auth-link text-black"
                        >
                          Terug naar login
                        </Link>
                      </div>
                    </>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TwoFactorSetup);
