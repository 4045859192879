import { types, Instance, flow, destroy } from "mobx-state-tree";
import makeInspectable from "mobx-devtools-mst";

import { UserService } from "services";
import ApiService from "services/ApiService";

import alertUtil from "util/alertUtil";

import EventModel from "./EventStore/EventModel";
import MeModel from "./UserStore/MeModel";
import RequestFiltersModel from "./Models/RequestFilters";

import AttendeeStore from "./AttendeeStore/AttendeeStore";
import EmployeeStore from "./EmployeeStore/EmployeeStore";
import EventStore from "./EventStore/EventStore";
import FaqStore from "./FaqStore/FaqStore";
import FragmentStore from "./FragmentStore/FragmentStore";
import HelpQuestionAnswerStore from "./HelpQuestionAnswerStore/HelpQuestionAnswerStore";
import HelpQuestionStore from "./HelpQuestionStore/HelpQuestionStore";
import IndustryStore from "./IndustryStore/IndustryStore";
import LocationStore from "./LocationStore/LocationStore";
import PerformanceStore from "./PerformanceStore/PerformanceStore";
import PersonaStore from "./PersonaStore/PersonaStore";
import PostStore from "./PostStore/PostStore";
import RoomStore from "./RoomStore/RoomStore";
import StatementStore from "./StatementStore/StatementStore";
import UserStore from "./UserStore/UserStore";

const RootStoreModel = types
  .model("RootStoreModel", {
    User: types.maybeNull(MeModel),
    currentEvent: types.maybeNull(types.reference(EventModel)),
    AttendeeStore,
    EmployeeStore,
    EventStore,
    FaqStore,
    FragmentStore,
    HelpQuestionAnswerStore,
    HelpQuestionStore,
    IndustryStore,
    LocationStore,
    PerformanceStore,
    PersonaStore,
    PostStore,
    RoomStore,
    StatementStore,
    UserStore,
    hasLoaded: types.maybe(types.boolean),
    isAuthenticated: types.maybe(types.boolean),
    has2faEnabled: types.maybe(types.boolean),
    complete2fa: types.maybe(types.boolean),
  })
  .actions((self) => ({
    setCurrentEvent: flow(function* (id: number) {
      ApiService.setEventId(id);

      self.LocationStore.reset();
      self.EmployeeStore.reset();
      self.FaqStore.reset();
      self.FragmentStore.reset();
      self.PostStore.reset();

      self.currentEvent = id as any;
      localStorage.setItem("currentEvent", id.toString());

      yield Promise.all([
        self.RoomStore.load(),
        self.PersonaStore.load(),
        self.HelpQuestionStore.load(),
        self.IndustryStore.load(),
      ]);
    }),
  }))
  .actions((self) => ({
    reset: () => {
      self.User && destroy(self.User);
      self.LocationStore.reset();
      self.EventStore.reset();
      self.PerformanceStore.reset();
      self.EmployeeStore.reset();
      self.RoomStore.reset();
      self.PersonaStore.reset();
      self.FaqStore.reset();
      self.FragmentStore.reset();
      self.PostStore.reset();
      self.StatementStore.reset();
      self.HelpQuestionStore.reset();
      self.HelpQuestionAnswerStore.reset();
      self.IndustryStore.reset();
      self.isAuthenticated = false;
      self.has2faEnabled = false;
      self.complete2fa = false;
      self.hasLoaded = false;
      self.currentEvent = null;
    },

    setIsAuthenticated: (isAuthenticated: boolean) => {
      self.isAuthenticated = isAuthenticated;
    },

    setHasLoaded: (hasLoaded: boolean) => {
      self.hasLoaded = hasLoaded;
    },

    setHas2faEnabled: (has2faEnabled: boolean) => {
      self.has2faEnabled = has2faEnabled;
    },

    setComplete2fa: (complete2fa: boolean) => {
      self.complete2fa = complete2fa;
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      const user = yield UserService.me();

      if (!user) {
        self.reset();
        ApiService.clearLocalStorageToken();
        ApiService.clearLocalStorageTwoFA();
        return;
      }

      if (user.role === "USER") {
        alertUtil.error(
          "U bent niet bevoegd om gebruik te maken van dit dashboard"
        );

        self.isAuthenticated = false;
        self.has2faEnabled = false;
        self.complete2fa = false;
        ApiService.clearLocalStorageToken();
        ApiService.clearLocalStorageTwoFA();
      } else {
        self.User = user;

        if (self.complete2fa) {
          yield self.EventStore.load();
          var eventId = Number(localStorage.getItem("currentEvent"));

          if (!eventId) eventId = self.EventStore.events[0].id;

          yield self.setCurrentEvent(eventId);
        }

        self.hasLoaded = true;
      }
    }),
  }));

const RootStore = RootStoreModel.create({
  UserStore: { filters: RequestFiltersModel.create() },
  LocationStore: { filters: RequestFiltersModel.create() },
  PerformanceStore: { filters: RequestFiltersModel.create() },
  EmployeeStore: { filters: RequestFiltersModel.create() },
  FaqStore: { filters: RequestFiltersModel.create() },
  FragmentStore: { filters: RequestFiltersModel.create() },
  PostStore: { filters: RequestFiltersModel.create() },
  HelpQuestionAnswerStore: { filters: RequestFiltersModel.create() },
  HelpQuestionStore: { filters: RequestFiltersModel.create() },
  EventStore: {},
  RoomStore: {},
  PersonaStore: {},
  StatementStore: {},
  AttendeeStore: {},
  IndustryStore: {},
});

export default makeInspectable(RootStore);

export interface RootStoreInstance extends Instance<typeof RootStoreModel> {}
