import {
  types,
  Instance,
  SnapshotIn,
  flow,
  applySnapshot,
} from "mobx-state-tree";
import { FragmentService } from "services";
import RoomModel from "stores/RoomStore/RoomModel";
import PersonaModel from "stores/PersonaStore/PersonaModel";
import alertUtil from "util/alertUtil";

export enum FRAGMENT_TYPES {
  AUDIO = "AUDIO",
  BREAK = "BREAK",
}

const FragmentModel = types
  .model({
    id: types.identifierNumber,
    title: types.maybe(types.string),
    body: types.maybe(types.string),
    buttonLabel: types.maybe(types.string),
    audio: types.maybe(types.string),
    type: types.enumeration(Object.values(FRAGMENT_TYPES)),
    room: types.maybe(types.reference(RoomModel)),
    roomId: types.maybe(types.number),
    startRoom: types.maybe(types.reference(RoomModel)),
    startRoomId: types.maybe(types.number),
    persona: types.maybe(types.reference(PersonaModel)),
    personaId: types.maybe(types.number),
  })

  .actions((self) => ({
    update: flow(function* (
      // @ts-ignore
      fragmentData: Partial<SnapshotIn<typeof FragmentModel>>,
      files: any
    ) {
      const fragment = yield FragmentService.updateWithIncludes(
        self.id,
        fragmentData,
        files
      );

      if (fragment) {
        applySnapshot(self, fragment);
        alertUtil.success("Het fragment is bijgewerkt");
      }
    }),
  }))
  .views((self) => ({
    get formattedType() {
      switch (self.type) {
        case "AUDIO":
        default:
          return "Audiofragment";
        case "BREAK":
          return "Pauzefragment";
      }
    },
  }));

export interface FragmentInstance extends Instance<typeof FragmentModel> {}
export interface FragmentSnapshot extends SnapshotIn<typeof FragmentModel> {}

export default FragmentModel;
