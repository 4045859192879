import { types, Instance, flow, destroy } from "mobx-state-tree";
import PerformanceModel, { PerformanceSnapshot } from "./PerformanceModel";
import PerformanceService from "services/PerformanceService";
import RequestFiltersModel from "stores/Models/RequestFilters";
import alertUtil from "util/alertUtil";

const PerformanceStore = types
  .model("PerformanceStore", {
    performances: types.array(PerformanceModel),
    current: types.maybeNull(types.reference(PerformanceModel)),
    filters: RequestFiltersModel,
  })
  .actions((self) => ({
    load: flow(function* (includeArchive: boolean = false) {
      const response = yield PerformanceService.listWithIncludes(
        self.filters,
        includeArchive
      );

      self.performances = response.results;
      self.filters.update(response);
    }),
    get: flow(function* (id: number) {
      const performance = yield PerformanceService.singleWithIncludes(id);
      if (performance) self.performances.push(performance);
      return performance;
    }),
    reset: () => {
      destroy(self.performances);
      self.filters = RequestFiltersModel.create();
    },
    create: flow(function* (performanceData: Partial<PerformanceSnapshot>) {
      const performance = yield PerformanceService.createWithIncludes(
        performanceData
      );

      if (performance) {
        self.performances.push(performance);
        alertUtil.success("De voorstelling is aangemaakt");
      }
    }),
    delete: flow(function* () {
      yield PerformanceService.delete(self.current.id);
      self.current = null;
    }),
  }))
  .actions((self) => ({
    setCurrent: flow(function* (id: number) {
      if (!self.performances.some((performance) => performance.id === id)) {
        const performance = yield self.get(id);
        if (!performance) return false;
      }

      self.current = id as any;
      return true;
    }),
    unsetCurrent: () => {
      self.current = null;
    },
  }));

export interface PerformanceStoreInstance
  extends Instance<typeof PerformanceStore> {}
// @ts-ignore
export default PerformanceStore;
