import {
  types,
  SnapshotIn,
  Instance,
  flow,
  applySnapshot,
} from "mobx-state-tree";
import EventModel from "stores/EventStore/EventModel";
import { EmployeeService } from "services";
import alertUtil from "util/alertUtil";

const EmployeeModel = types
  .model("EmployeeModel", {
    id: types.identifierNumber,
    name: types.string,
    role: types.maybe(types.string),
    description: types.maybe(types.string),
    image: types.maybe(types.string),
    stageImage: types.maybe(types.string),
    event: types.reference(EventModel),
    eventId: types.number,
    type: types.enumeration(["ACTOR", "MUSICIAN", "MANAGEMENT"]),
  })
  .actions((self) => ({
    update: flow(function* (
      // @ts-ignore
      employeeData: Partial<SnapshotIn<typeof EmployeeModel>>,
      files: any
    ) {
      const employee = yield EmployeeService.updateWithIncludes(
        self.id,
        employeeData,
        files
      );

      if (employee) {
        applySnapshot(self, employee);
        alertUtil.success("De medewerker is bijgewerkt");
      }
    }),
  }));

export interface EmployeeInstance extends Instance<typeof EmployeeModel> {}
export interface EmployeeSnapshot extends SnapshotIn<typeof EmployeeModel> {}

export default EmployeeModel;
