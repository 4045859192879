import {
  types,
  SnapshotIn,
  Instance,
  flow,
  applySnapshot,
} from "mobx-state-tree";
import EventModel from "stores/EventStore/EventModel";
import { PersonaService } from "services";
import alertUtil from "util/alertUtil";

const PersonaModel = types
  .model("PersonaModel", {
    id: types.identifierNumber,
    name: types.string,
    event: types.reference(EventModel),
    eventId: types.number,
    introFragment: types.maybe(types.string),
  })
  .actions((self) => ({
    update: flow(function* (
      // @ts-ignore
      personaData: Partial<SnapshotIn<typeof PersonaModel>>,
      files: any
    ) {
      const persona = yield PersonaService.updateWithIncludes(
        self.id,
        personaData,
        files
      );

      if (persona) {
        applySnapshot(self, persona);
        alertUtil.success("Het personage is bijgewerkt");
      }
    }),
  }));

export interface PersonaInstance extends Instance<typeof PersonaModel> {}
export interface PersonaSnapshot extends SnapshotIn<typeof PersonaModel> {}

export default PersonaModel;
