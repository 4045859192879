import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select-v2";

import Loader from "components/Loader";
import { FragmentInstance } from "stores/FragmentStore/FragmentModel";
import { TextInput, FileInput } from "./FormElements";
import { RootStore } from "stores";

const FragmentForm = ({
  onSubmitCallback,
  onRemoveCallback,
  fragment,
  persona,
  startRoomId,
}: {
  onSubmitCallback: (
    fragmentData: Partial<FragmentInstance>,
    files: any
  ) => Promise<void>;
  onRemoveCallback?: () => void;
  fragment?: FragmentInstance;
  room?: { value: string; label: string };
  persona?: { value: string; label: string };

  startRoomId?: number;
}) => {
  const onSubmit = async (formEvent) => {
    setLoading(true);

    formEvent.preventDefault();
    const fragmentData: Partial<FragmentInstance> = {
      roomId: selectedRoom.value,
      personaId: persona ? persona.value : fragment.persona.id,
      title: formEvent.target[2] ? formEvent.target[2].value : "",
      body: formEvent.target[3] ? formEvent.target[3].value : "",
      buttonLabel: formEvent.target[4] ? formEvent.target[4].value : "",
      type: selectedType.value,
      startRoomId: startRoomId || fragment.startRoomId,
    };

    if (fragment) {
      fragmentData.id = fragment.id;
    }

    const files = {
      audioFile,
    };

    await onSubmitCallback(fragmentData, files);

    setLoading(false);
  };

  const [loading, setLoading] = useState(false);
  const [audioFile, setAudioFile] = useState<any>();
  const [selectedRoom, setSelectedRoom] = useState<any>(
    fragment && fragment.room
      ? { label: fragment.room.name, value: fragment.room.id }
      : ""
  );
  const [selectedType, setSelectedType] = useState(
    fragment?.type
      ? {
          label: fragment.type === "AUDIO" ? "Audiofragment" : "Pauzefragment",
          value: fragment.type,
        }
      : {}
  );

  const roomOptions = RootStore.RoomStore.rooms.map((room) => ({
    label: room.name,
    value: room.id,
  }));

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <label>Type fragment</label>

        <Select
          options={[
            {
              value: "AUDIO",
              label: "Audiofragment",
            },
            {
              value: "BREAK",
              label: "Pauzefragment",
            },
          ]}
          la
          value={selectedType}
          onChange={(item) => {
            setSelectedType(item);
          }}
        ></Select>
      </Form.Group>

      {selectedType.value === "AUDIO" && (
        <>
          <Form.Group>
            <label>Huidige kamer</label>
            <Select
              options={roomOptions}
              value={selectedRoom}
              onChange={(item) => {
                setSelectedRoom(item);
              }}
            ></Select>
          </Form.Group>

          <Form.Group>
            <label>Personage</label>
            <p>{persona ? persona.label : fragment.persona.name}</p>
          </Form.Group>

          <TextInput
            label="Titel"
            id="title"
            placeholder="Titel"
            required={selectedType.value !== "BREAK"}
            defaultValue={fragment?.title}
          ></TextInput>

          <TextInput
            label="Inhoud"
            id="body"
            placeholder="Inhoud"
            required={selectedType.value !== "BREAK"}
            defaultValue={fragment?.body}
          ></TextInput>

          <TextInput
            label="Tekst in knop"
            id="buttonLabel"
            placeholder="Tekst in knop"
            required={true}
            defaultValue={fragment?.buttonLabel}
          ></TextInput>

          <FileInput
            required={selectedType.value !== "BREAK" && !fragment}
            label="Audio bestand"
            id="iconFile"
            type="audio"
            defaultValue={fragment?.audio}
            stateFile={audioFile}
            onFileChange={(event) => setAudioFile(event.target.files[0])}
          ></FileInput>
        </>
      )}

      <Button type="submit" className="btn btn-primary mr-2 position-relative">
        <Loader position="right" display={loading}></Loader>
        {fragment ? "Opslaan" : "Toevoegen"}
      </Button>

      {fragment && (
        <Button variant="danger" onClick={onRemoveCallback}>
          Verwijderen
        </Button>
      )}
    </Form>
  );
};

export default FragmentForm;
